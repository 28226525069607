<template>
  <div class="recharge" center_bgc>
    <div class="swiper_out">
      <span class="word_title">会员套餐</span>
      <div
        class="swiper-container"
        id="swiper_container"
        v-if="swiper_list.length != 0"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiper_list"
            :key="index"
          >
            <div class="set_meal" @click="choose_it(item, 1)">
              <div
                class="label"
                v-if="item.goods_label !== 0"
                :class="[
                  item.goods_label === 2 ? 'bc_red' : '',
                  item.goods_label === 3 ? 'bc_purper' : '',
                ]"
              >
                {{ label_style[item.goods_label] }}
              </div>
              <span class="goods_name">{{ item.goods_name || "- -" }}</span>
              <p class="goods_time">{{ item.member_day || "- -" }}天</p>
              <p class="goods_money">￥{{ item.price || "- -" }}</p>
              <span class="discription">{{ item.goods_desc || "暂 无" }}</span>
            </div>
          </div>
        </div>
        <div class="swiper-button-next" v-show="false"></div>
        <div class="swiper-button-prev" v-show="false"></div>
        <div class="arr_right" @click="next()" v-if="slider_num">
          <i class="iconfont icon-jiantou"></i>
        </div>
        <div class="arr_left" @click="prev()" v-if="slider_num">
          <i class="iconfont icon-jiantou-copy"></i>
        </div>
      </div>
    </div>
    <div class="buy_coin">
      <span class="word_title">购买书币</span>
      <div class="coin_box">
        <div
          class="coin_out"
          v-for="(item, index) in coin_list"
          :key="index"
          flex
          center
          align
        >
          <div
            class="coin_item"
            @click="choose_it(item, 2, index)"
            :style="{ background: bc_arr[index] }"
          >
            <div class="label_down" v-if="item.goods_label !== 0">
              <span>{{ label_style[item.goods_label] }}</span>
              <i
                :class="[
                  item.goods_label === 2 ? 'bc_red' : '',
                  item.goods_label === 3 ? 'bc_purper' : '',
                ]"
                class="iconfont icon-biaoqian1"
              ></i>
            </div>
            <div class="coin_top">
              <div class="item_name">
                <span>{{ item.goods_name || "- -" }}</span>
                <span>
                  {{ item.book_coins
                  }}{{
                    item.give_away_coins ? ` + ${item.give_away_coins}` : null
                  }}书币
                </span>
              </div>
              <span class="item_money">￥{{ item.price || 0 }}</span>
            </div>
            <span class="discription"
              >{{ item.goods_desc || (item.give_away_coins ? "" : "暂无描述")
              }}{{
                item.give_away_coins ? `  赠${item.give_away_coins}` : ""
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="recharge_bc" v-if="buy_recharge">
      <div class="recharge_box">
        <i class="iconfont icon-guanbi" @click="close_recharge"></i>
        <div class="choose_box" v-if="!go_cost" v-loading="having_do">
          <div class="recharge_title">
            <p class="">购买会员套餐</p>
            <p v-if="false">购买书币</p>
          </div>
          <div class="or_bug">
            <span>是否购买 :</span>
            <div>
              <div class="set_meal" v-if="choose_item_type === 1">
                <div
                  class="label"
                  v-if="choose_item.goods_label !== 0"
                  :class="[
                    choose_item.goods_label === 2 ? 'bc_red' : '',
                    choose_item.goods_label === 3 ? 'bc_purper' : '',
                  ]"
                >
                  {{ label_style[choose_item.goods_label] }}
                </div>
                <span class="goods_name">{{
                  choose_item.goods_name || "- -"
                }}</span>
                <p class="goods_time">
                  {{ choose_item.member_day || "- -" }}天
                </p>
                <p class="goods_money">￥{{ choose_item.price || "- -" }}</p>
                <span class="discription">{{
                  choose_item.goods_desc || "暂 无"
                }}</span>
              </div>
              <div
                class="coin_item"
                v-if="choose_item_type === 2"
                :style="{ background: bc_arr[choose_item.index] }"
              >
                <div class="label_down" v-if="choose_item.goods_label !== 0">
                  <span>{{ label_style[choose_item.goods_label] }}</span>
                  <i
                    :class="[
                      choose_item.goods_label === 2 ? 'bc_red' : '',
                      choose_item.goods_label === 3 ? 'bc_purper' : '',
                    ]"
                    class="iconfont icon-biaoqian1"
                  ></i>
                </div>
                <div class="coin_top">
                  <div class="item_name">
                    <span>{{ choose_item.goods_name || "- -" }}</span>
                    {{ choose_item.book_coins
                    }}{{
                      choose_item.give_away_coins
                        ? ` + ${choose_item.give_away_coins}`
                        : null
                    }}书币
                  </div>
                  <span class="item_money">￥{{ choose_item.price || 0 }}</span>
                </div>
                <span class="discription"
                  >{{
                    choose_item.goods_desc ||
                    (choose_item.give_away_coins ? "" : "暂无描述")
                  }}{{
                    choose_item.give_away_coins
                      ? `  赠${choose_item.give_away_coins}`
                      : ""
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="recharge_type">
            <span>请选择支付方式 :</span>
            <div>
              <el-radio v-model="radio" :label="2"
                ><span class="type_text"
                  ><i class="iconfont icon-wechat"></i> 微信支付</span
                ></el-radio
              >
              <el-radio v-model="radio" :label="1"
                ><span class="type_text"
                  ><i class="iconfont icon-zhifubao"></i>支付宝支付</span
                >
              </el-radio>
            </div>
          </div>
          <div class="recharge_btn">
            <span
              >合计: ￥ <span class="money">{{ choose_item.price }}</span></span
            >
            <el-button
              type="primary"
              size="small"
              @click="go_recharge()"
              :disabled="is_recharge"
              >支 付</el-button
            >
          </div>
        </div>
        <div class="code_out" v-show="go_cost">
          <div>
            <span class="warn_word warn_word_sec" v-if="!out_time"
              >请在 {{ effective_time }} 前完成支付</span
            >
            <div class="qrcode_box">
              <div id="qrcode"></div>
              <div class="pay_way" v-if="had_pay">
                <div class="pay_content">
                  <div class="succ_word">
                    <i class="iconfont icon-chenggong"></i>
                    <span>支付成功</span>
                  </div>
                  <div class="succ_btn">
                    <span>{{ `${time_pos}s后` }}</span>
                    <span class="close_win" @click="close_recharge()"
                      >关闭窗口</span
                    >
                  </div>
                </div>
              </div>
              <div class="pay_way out_time_text" v-if="out_time">
                <p>二维码已过期</p>
                <p>请点击下方按钮刷新</p>
              </div>
            </div>
            <span class="type_text btm_type" v-if="radio === 2"
              ><i class="iconfont icon-wechat"></i
              >请用微信扫描二维码完成支付</span
            >
            <span class="type_text btm_type" v-if="radio === 1"
              ><i class="iconfont icon-zhifubao"></i>
              请用支付宝扫描二维码完成支付</span
            >
            <el-button
              type="primary"
              size="small"
              v-if="out_time"
              @click="bush_code()"
              :disabled="!out_time"
              >刷新验证码</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { Buycenter, aviod_pay, is_pay } from "@/https/mine_center.js";
import { setInterval, setTimeout } from "timers";
export default {
  name: "recharge",
  components: {},
  data() {
    return {
      coin_list: [
        // {name:'贫农',coin_num:1000,money:'10',discr:'商品描述商品描述商品描述商品描述商品描述'},
        // {name:'中农',coin_num:2000,money:'20',discr:'商品描述商品描述商品描述商品描述商品描述'},
        // {name:'富农',coin_num:3000,money:'30',discr:'商品描述商品描述商品描述商品描述商品描述'},
        // {name:'小康',coin_num:6000,money:'50',discr:'商品描述商品描述商品描述商品描述商品描述'},
      ],
      swiper: null,
      slider_num: false,
      swiper_list: [],
      choose_item: {},
      choose_item_type: "",
      radio: 2,
      buy_recharge: false,
      go_cost: false,
      label_style: ["无", "限时", "热卖", "推荐"],
      label_color: ["", "rgba(255,153,102,0.75)", "#f95e5a", "#aba5f0"],
      is_recharge: false,
      recharge_off: false,
      having_do: false,
      recharge_col: 0,
      bc_arr: [
        "#ECF2FE",
        "#E6F2FF",
        "#ECF2FE",
        "#E6F2FF",
        "#CDDAFA",
        "#DEEFFF",
        "#E4F8F9",
        "#FEF4EA",
        "#F2EADD",
        "#FCF5F5",
        "#E4F8F9",
      ],
      time_init_pay: null,
      set_time_two: null,
      pay_text: "",
      time_pos: 3,
      had_pay: false,
      out_time: true,
      code_time: null,
      qrcode_all: null,
      effective_time: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.avoid_list();
      this.init_swiper();
    });
  },
  methods: {
    avoid_list() {
      Buycenter().then((res) => {
        if (res.code === 0) {
          this.swiper_list = res.data.Viplist;
          this.coin_list = res.data.Bookcoin;
          this.swiper_list.length > 5
            ? (this.slider_num = true)
            : (this.slider_num = false);
        }
        console.log(res);
      });
    },
    init_swiper() {
      setTimeout(() => {
        this.swiper = new Swiper(".swiper-container", {
          // loop: true,
          // watchSlidesProgress: true,
          // watchSlidesVisibility: true,
          slidesPerView: 5,
          freeMode: true,
          freeModeMomentum: false,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }, 300);
    },
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
    choose_it(row, index, num) {
      this.choose_item_type = index;
      this.choose_item = row;
      this.choose_item.index = num;
      this.buy_recharge = true;
    },
    close_recharge() {
      this.choose_item = {};
      this.choose_item_type = "";
      this.buy_recharge = false;
      this.pay_sn = "";
      this.recharge_col = 0;
      this.go_cost = false;
      this.having_do = false;
      this.is_recharge = false;
      this.had_pay = false;
      this.clear_time();
      this.clear_time_two();
      this.clear_code_time();
      // this.$router.go(0)
      window.location.reload();
    },
    go_recharge() {
      let params = {
        goods_id: this.choose_item.id,
        type: this.choose_item_type,
        pay_way: this.radio,
      };
      if (this.qrcode_all) {
        document.getElementById("qrcode").innerHTML = "";
      }
      this.effective_time = new Date(new Date().getTime() + 1800000).Format(
        "hh:mm:ss"
      );
      this.having_do = true;
      this.is_recharge = true;
      this.clear_code_time();
      aviod_pay(params)
        .then((res) => {
          if (res.code === 0) {
            this.pay_sn = res.data.trade_no;
            this.qrcode_all = new QRCode("qrcode", {
              width: 200, // 设置宽度，单位像素
              height: 200, // 设置高度，单位像素
              text: res.data.qr, // 设置二维码内容或跳转地址
            });
            this.out_time = false;
            this.go_cost = true;
            this.set_time();
            this.code_time_init();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .finally(() => {
          this.having_do = false;
          this.is_recharge = false;
        });
    },
    // 二维码过期定时器
    code_time_init() {
      this.code_time = setInterval(() => {
        this.out_time = true;
      }, 1800000);
    },
    // 刷新二维码
    bush_code() {
      this.go_recharge();
    },
    //清除二维码过期定时器
    clear_code_time() {
      this.out_time = false;
      clearInterval(this.code_time);
      this.code_time = null;
    },
    pay_sure() {
      let params = {
        pay_sn: this.pay_sn,
        pay_way: this.radio,
      };
      this.having_do = true;
      this.recharge_off = true;
      is_pay(params)
        .then((res) => {
          if (res.code === 0) {
            this.pay_text = "支付成功";
            this.recharge_col = 1;
            this.clear_time();
            this.pay_success();
            this.had_pay = true;
          } else {
            // this.$message.warning(res.msg)
          }
        })
        .finally(() => {
          this.having_do = false;
          this.recharge_off = false;
        });
    },

    set_time() {
      this.time_init_pay = setInterval(() => {
        this.pay_sure();
      }, 1000);
    },
    pay_success() {
      this.set_time_two = setInterval(() => {
        if (this.time_pos >= 1) {
          this.time_pos -= 1;
        } else {
          this.clear_time_two();
          this.close_recharge();
        }
      }, 1000);
    },
    clear_time() {
      clearInterval(this.time_init_pay);
      this.time_init_pay = null;
    },
    clear_time_two() {
      this.time_pos = 3;
      clearInterval(this.set_time_two);
      this.set_time_two = null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.recharge {
  // border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 0 15px;
}
.word_title {
  padding: 10px 0;
  font-size: 14px;
}
.coin_box {
  @include flex-row-left();
  //  justify-content: space-between
}
.swiper_out,
.buy_coin {
  display: flex;
  @include flex-column();
}
.set_meal {
  width: 160px;
  height: 200px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  @include flex-column-center();
  padding: 20px 10px 30px;
  position: relative;
  box-shadow: 0 0 5px #d9d9d9;
  cursor: pointer;
  background: rgba(254, 244, 234, 0.6);
  &:hover {
    background: rgba(254, 244, 234, 1);
  }
  .label {
    position: absolute;
    top: 8px;
    right: -28px;
    width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    background: rgba(255, 153, 102, 0.75);
    color: #ffffff;
    transform: rotate(45deg);
    @include flex-center();
  }
  .goods_name {
    font-size: 18px;
    font-weight: bold;
  }
  .goods_time {
    padding: 15px 0 10px;
    font-size: 12px;
  }
  .goods_money {
    padding: 5px 0 15px;
    font-size: 20px;
    color: #ff9966;
    font-weight: bold;
  }
}
.coin_out {
  width: calc(100% / 3);
}
.coin_item {
  position: relative;
  width: 280px;
  padding: 25px 15px;
  margin: 0 0 20px 0;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 5px #d3d3d3;
  }
  @include flex-column();
  .coin_top {
    @include flex-row-between_start();
    .item_name {
      @include flex-column();
      span:nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }
      span:nth-child(2) {
        padding: 10px 0;
      }
    }
    .item_money {
      font-size: 20px;
      color: #ff9966;
      font-weight: bold;
    }
  }
  .discription {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 5px;
  }
}

.swiper-container {
  position: relative;
  width: 100%;
  .swiper-slide {
    @include flex-center();
    width: 184px !important;
  }
}
.arr_left,
.arr_right {
  position: absolute;
  @include flex-center();
  background: rgba(226, 226, 226, 0.8);
  color: #ffffff;
  transform: translateY(-50%);
  border-radius: 8px;
  top: 50%;
  width: 40px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  &:hover {
    background: rgba(226, 226, 226, 0.6);
  }
  i {
    font-size: 30px;
  }
}
.arr_left {
  left: 0;
}
.arr_right {
  right: 0;
}

.recharge_bc {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  .recharge_box {
    width: 700px;
    min-height: 500px;
    padding: 15px 20px;
    background: #ffffff;
    border-radius: 4px;
    @include center_positon_r();
    @include flex-column();
    // align-items: center;
    > .iconfont {
      font-size: 16px;
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;
      color: #dadada;
      z-index: 2001;
      &:hover {
        color: #838383;
      }
    }
    .recharge_title {
      font-size: 24px;
      width: 100%;
      text-align: center;
      padding: 20px 0;
    }
    .or_bug {
      @include flex-row-start();
      padding: 15px 0;
      > span {
        text-align: end;
        width: 110px;
        margin-right: 20px;
      }
    }
    .recharge_type {
      @include flex-row-start();
      padding: 15px 0;
      > span {
        text-align: end;
        width: 110px;
        margin-right: 20px;
      }

      > div {
        @include flex-row();
        /deep/.el-radio {
          display: flex;
        }
        .type_text {
          @include flex-row();
        }
      }
    }
    .recharge_btn {
      width: 100%;
      @include flex-row-end();
      margin-top: 20px;
      padding: 15px 30px;
      .money {
        font-size: 30px;
      }
      > span {
        margin-right: 20px;
      }
    }
    .code_out {
      width: 100%;
      height: 100%;
      min-height: 470px;
      z-index: 2000;
      background: rgba(255, 255, 255, 0.8);
      > div {
        @include center_positon_a();
        @include flex-column-center();
        #qrcode {
          margin-bottom: 20px;
        }
      }
      p {
        margin: 20px 0 10px;
      }
    }
  }
}
.icon-wechat {
  padding-right: 5px;
  color: rgb(105, 187, 100);
}
.icon-zhifubao {
  padding-right: 5px;
  color: rgb(2, 169, 241);
}
.label_down {
  position: absolute;
  top: 0;
  left: 0;
  width: 62px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  span {
    position: absolute;
    top: 48%;
    left: 48%;
    line-height: 20px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  i {
    position: absolute;
    color: rgba(255, 153, 102, 0.75);
    width: 100%;
    font-size: 70px;
    line-height: 24px;
    height: 24px;
    left: -4px;
    top: 2px;
    z-index: 1;
  }
  // background:rgba(255, 153, 102, 0.75);
  // &::after{
  //   border-left: 8px solid transparent;
  //   border-bottom: 24px solid #ffffff;
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }
}
.bc_purper {
  color: rgba(171, 165, 240, 0.75) !important;
}

.bc_red {
  color: rgba(249, 94, 90, 0.75) !important;
}
.recharge_col {
  color: #67c23a !important;
}
.warn_word {
  color: rgba(249, 94, 90, 0.75);
}
.warn_word_sec {
  padding-bottom: 15px;
}
.qrcode_box {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
#qrcode {
  position: relative;
}
.pay_way {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  top: 0;
  left: 0;
  @include flex-center();
  .pay_content {
    @include flex-column-center();
    .succ_word {
      @include flex-row();
      font-size: 16px;
      i {
        font-size: 24px;
        color: rgb(82, 196, 26);
      }
    }
    .succ_btn {
      padding: 15px 0 0;
      @include flex-row();
      .close_win {
        padding-left: 5px;
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
.btm_type {
  padding: 10px 0;
}
.out_time_text {
  flex-direction: column;
  p {
    margin: 0 !important;
    padding: 5px 0;
  }
}
</style>